import { Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ContainerSmall } from "../../components/ContainerSmall";
import { useBiographyShow } from "./hooks/useBiographyShow";
import { ButtonBioOptions } from "./components/ButtonBioOptions";
import { IconPdf } from "./components/icons/iconPdf";
import { IconPdfAudio } from "./components/icons/iconPdfAudio";
import { api } from "../../service/api";
import { useState } from "react";
import { ModalPagePDF } from "../../components/modalPagePDF";
import { BOOK_PREVIEW } from "../../assets";
import { RiBook2Fill } from "react-icons/ri";
import { useAuth } from "../../hooks/useAuth";

export function BiographyShow() {
  const { user } = useAuth();

  let { id } = useParams();
  const navigate = useNavigate();

  const { dataList, loading } = useBiographyShow(id as string);

  const [isGeneretePDF, setIsGeneretePDF] = useState(false);
  const [linkPdf, setLinkPdf] = useState("");
  const [isOpenPDF, setIsOpenPDF] = useState(false);

  async function generetePDF() {
    try {
      setIsGeneretePDF(true);
      var type_page_pdf = window.matchMedia("(max-width: 700px)").matches
        ? "mobile"
        : "desktop";

      const response = await api.post("book/page/generete", {
        biography_id: id,
        type_page_pdf,
      });

      setLinkPdf(response.data.path);
      if (response.data.path) {
        setIsOpenPDF(true);
      }
    } catch (error) {
      alert("Erro ao gerar o PDF.");
    } finally {
      setIsGeneretePDF(false);
    }
  }

  function printCopies(id: string) {
    if (!user.neighborhood || !user.public_place || !user.number) {
      navigate(`/minha-conta`);
    } else {
      navigate(`/livro/solicitar-livro-fisico/${id}`);
    }
  }

  return (
    <>
      <ModalPagePDF
        link_pdf={linkPdf}
        is_open={isOpenPDF}
        onClose={() => {
          setIsOpenPDF(false);
        }}
      />

      <ContainerSmall>
        <Flex flexDirection="column" width="100%">
          {!loading && dataList && (
            <Flex
              w="100%"
              marginTop="50px"
              marginBottom="50px"
              flexDirection="column"
            >
              <Text
                display={["none", "none", "flex"]}
                color="#181818"
                fontWeight={500}
                fontSize="28px"
              >
                Escolha a forma que você deseja para o seu livro
              </Text>

              <Flex
                mt="50px"
                flexDirection={["column", "column", "row"]}
                margin={["auto", "auto", "inherit"]}
                gap="40px"
              >
                <Flex
                  width="216px"
                  height="304px"
                  borderWidth="1px"
                  borderColor="#36A9E1"
                  borderRadius="10px"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  backgroundColor="#36A9E1"
                  position="relative"
                >
                  {!dataList.book?.id && (
                    <Image
                      src={BOOK_PREVIEW}
                      alt={"book"}
                      width="216px"
                      height="304px"
                      borderRadius="10px"
                    />
                  )}
                  {dataList.book && (
                    <Image
                      src={dataList.book.path}
                      alt={dataList.book.title}
                      width="216px"
                      height="304px"
                      borderRadius="10px"
                    />
                  )}

                  {!dataList.book && (
                    <Text
                      position="absolute"
                      color="#000000"
                      fontSize="22px"
                      textAlign="center"
                      fontWeight={500}
                    >
                      {dataList.title}
                    </Text>
                  )}
                </Flex>

                <Flex flexDirection="column">
                  <Text
                    color="#181818"
                    display={["none", "none", "flex"]}
                    fontWeight={500}
                    fontSize="18px"
                  >
                    Seu livro está finalizado.
                  </Text>
                  <Flex
                    mt={["0px", "0px", "30px"]}
                    gap="20px"
                    flexDirection="column"
                  >
                    <ButtonBioOptions
                      isLoading={isGeneretePDF}
                      title="Gerar pdf"
                      icon={<IconPdf />}
                      onPress={() => {
                        generetePDF();
                      }}
                    />
                    <ButtonBioOptions
                      title="Gerar Audio Book"
                      icon={<IconPdfAudio />}
                      onPress={() => {}}
                      isDisabled
                    />
                    <ButtonBioOptions
                      title="Solicitar livro físico"
                      icon={<RiBook2Fill color="#36A9E1" size={32} />}
                      onPress={() => {
                        printCopies(dataList.id);
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </ContainerSmall>
    </>
  );
}
